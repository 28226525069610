<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="用户user_id：">
          <el-input v-model="uid" placeholder="请输入用户user_id"></el-input>
        </el-form-item>
        <el-form-item label="商品id：">
          <el-input v-model="item_id" placeholder="itemid"></el-input>
        </el-form-item>
        <el-form-item label="订单号：">
          <el-input v-model="orderId" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in payStatusList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="jdList" border>
      <el-table-column fixed prop="order_id" label="编号" width="150">
      </el-table-column>
      <el-table-column fixed label="用户user_id(user_key)" width="200">
        <template slot-scope="scope">
          {{ scope.row.user_id }} ( {{ scope.row.user_key }} )
        </template>
      </el-table-column>
      <el-table-column
        prop="order_sn"
        label="订单号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="item_id"
        label="商品ID"
        width="150"
      ></el-table-column>
      <el-table-column
        label="商品标题"
        prop="item_title"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="pay_amount"
        label="支付金额"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="user_commission"
        label="用户佣金"
        width="100px"
      ></el-table-column>
      <el-table-column label="下单订单">
        <template slot-scope="scope">
          {{ scope.row.order_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.status == 1
                ? 'green'
                : scope.row.status == 3
                ? 'blue'
                : scope.row.status == 4
                ? 'warn'
                : scope.row.status == 5 ||
                  scope.row.status == 6 ||
                  scope.row.status == 7
                ? 'red'
                : ''
            "
          >
            {{ scope.row.status | status }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="alterDialog(scope.row)"
            v-if="scope.row.status == 1 || scope.row.status == 2"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="settlement(scope.row.order_id)"
            v-if="scope.row.status == 1"
            >结算</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="修改"
      :visible.sync="dialog"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="用户user_id">
          <el-input v-model="unionid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="佣金">
          <el-input v-model="user_commission" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination
      background
      layout="total, sizes,prev, pager, next,jumper"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
  </section>
  <!-- /.content -->
</template>

<script>
let common = JSON.parse(window.localStorage.getItem('common'));
import * as api from '@/config/api';
export default {
  name: 'JdOrder',
  components: {},

  data() {
    return {
      jdList: [],
      total: 0,
      page: 1,
      pagesize: 10,
      goods_id: '',
      orderId: '',
      payStatusList: common.order_status_list,
      uid: '',
      item_id: '',
      status: '',
      loading: true,
      dialog: false,
      order_id: '',
      unionid: '',
      user_commission: '',
    };
  },
  watch: {},
  filters: {
    status(val) {
      let name = '';
      if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
        common.order_status_list.map((a, index) => {
          if (val === a.type) {
            name = a.name;
          }
        });
      }
      return name;
    },
    compare(val) {
      return val === 1 ? '是' : '否';
    },
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return '';
      }
      return (
        day.getFullYear() +
        '-' +
        `${
          day.getMonth() + 1 < 10
            ? '0' + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        '-' +
        `${day.getDate() < 10 ? '0' + day.getDate() : day.getDate()}` +
        ' ' +
        `${day.getHours() < 10 ? '0' + day.getHours() : day.getHours()}` +
        ':' +
        `${day.getMinutes() < 10 ? '0' + day.getMinutes() : day.getMinutes()}` +
        ':' +
        `${day.getSeconds() < 10 ? '0' + day.getSeconds() : day.getSeconds()}`
      );
    },
  },
  mounted() {
    this.getDDOrderList();
  },
  methods: {
    settlement(id) {
      this.$confirm('请确定是否结算该订单', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          api.ksOrderSettlement(
            {
              order_id: id,
            },
            res => {
              this.$message({
                type: 'success',
                message: '结算成功!',
              });
              this.getDDOrderList();
            },
          );
        })
        .catch(() => {});
    },
    alterDialog({ order_id, user_id, user_commission }) {
      this.order_id = order_id;
      this.unionid = user_id;
      this.user_commission = user_commission;
      this.dialog = true;
    },
    alter() {
      api.ksOrderUpdateItem(
        {
          order_id: this.order_id,
          user_id: this.unionid,
          user_commission: this.user_commission,
        },
        res => {
          console.log(res);
          this.$message({
            type: 'success',
            message: res.msg,
          });
          this.dialog = false;
          this.seach(1);
        },
        err => {
          console.log(err);
        },
      );
    },
    close() {
      this.show = false;
      this.getDDOrderList();
    },
    getDDOrderList(page, size) {
      this.loading = true;
      api.ksOrderList(
        {
          user_id: this.uid,
          order_sn: this.orderId,
          status: this.status,
          item_id: this.item_id,
          page: page ? page : this.page,
          pagesize: size ? size : this.pagesize,
        },
        res => {
          page && (this.page = page);
          this.jdList = res.data.list;
          this.total = Number(res.data.count);
          this.loading = false;
        },
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getDDOrderList();
    },
    currentChange(val) {
      this.page = val;
      this.getDDOrderList();
    },
    seach(page, size) {
      this.getDDOrderList(page, size);
    },
  },
};
</script>
<style scoped>
.cell {
  text-align: center;
}

.btn-box {
  text-align: left;
  margin-bottom: 20px;
}

img {
  width: 150px;
}

.red {
  color: #f56c6c;
}

.blue {
  color: #409eff;
}

.green {
  color: #67c23a;
}

.warn {
  color: #e6a23c;
}
</style>
